import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Anchor from "./Anchor";

const CustomButton = ({ label, url, type }) => {
  return (
    <Anchor
      type={type || "button"}
      href={url}
      className="custom-btn d-flex align-items-center justify-content-between p-2 bg-white mb-4 mt-4"
    >
      <h5 className="m-0 mx-3">{label}</h5>
      <StaticImage src="../../images/icons/redRightArrow.png" alt={label} />
    </Anchor>
  );
};

export default CustomButton;
