import React from "react";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../components/common/Layout";
import CustomButton from "../components/elements/CustomButton";
import Anchor from "../components/elements/Anchor";
import Seo from "../components/meta/Seo";

const Ideas = ({ data }) => {
  if (!data) return null;
  const { settings, page } = data;
  const productCategoryList = settings.data.body
    .filter((item) => item.slice_type === "menu")
    .map((category) => {
      return (
        <Col
          key={category.id}
          md={6}
          xl={4}
          className="ideas-card mb-4 rounded category-image-wrapper"
        >
          <GatsbyImage
            image={category.primary.image.gatsbyImageData}
            alt={category.primary.image.alt}
            className="rounded"
          />
          <Anchor href={`posts?ideas=${category.primary.post_idea_tag}`}>
            <CustomButton label={category.primary.label} />
          </Anchor>
        </Col>
      );
    });

  return (
    <>
      <Seo page={page} />
      <Layout settings={settings}>
        <Container className="ideas-page-container my-5">
          <Row>{productCategoryList}</Row>
        </Container>
      </Layout>
    </>
  );
};

export const query = graphql`
  query IdeasPageQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicIdeas {
      _previewable
      alternate_languages {
        type
        uid
        lang
      }
      lang
      type
      url
      data {
        meta_title
        meta_description
        meta_image {
          alt
          dimensions {
            height
            width
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(Ideas);

// Post tags to filter Post Ideas:
// koufomata
// ependiseis-ktirion
// kouzines
// mpania
// ntoulapes
// dapeda
// portes
// kagkela
// pergoles
// tentes
// epipla
// fotistika
// franke
